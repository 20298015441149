import React, {createContext, useEffect, useMemo, useState} from 'react'
import {useCookieState} from "../../Effect/Cookie";

export const AuthContext = createContext({})
export const useAuth = () => {
    const [token, setToken] = useCookieState("TOKEN")
    const isAuthorized = useMemo(() => !!token, [token])
    return {token, setToken, isAuthorized}
}

export const AuthorityProvider = ({children}) => {
    const {token, setToken, isAuthorized} = useAuth()
    return <AuthContext.Provider value={{token, setToken, isAuthorized}}>{children}</AuthContext.Provider>
}

export const withAuth = (Component) => (props) =>
    <AuthContext.Consumer>
        {<Component/>}
    </AuthContext.Consumer>