import React, {lazy} from "react";
import {Redirect} from "react-router-dom";
import {GoldOutlined, ShopOutlined, TeamOutlined} from "@ant-design/icons";

const Home = lazy(() => import('./Pages/Home/Home'))
const Family = lazy(() => import('./Pages/Family/Family'))
const Detail = lazy(() => import('./Pages/Family/Detail'))
const Device = lazy(() => import('./Pages/Family/Device'))
const Alarm = lazy(() => import('./Pages/Family/Alarm'))
const Package = lazy(() => import('./Pages/Shop/Package'))
const Order = lazy(() => import('./Pages/Shop/Order'))
const Banner = lazy(() => import('./Pages/Layout/Banner'))
const Article = lazy(() => import('./Pages/Layout/Article'))

const Login = lazy(() => import('./Pages/Auth/Login'))

export const routes = {
    key: 'home', path: '/', component: Home, exact: true,
    routes: [
        {
            key: 'family', path: '/family', name: '家庭管理', icon: <TeamOutlined/>,
            render: () => <Redirect to='/family/family'/>, exact: true,
            routes: [
                {key: 'family-family', path: '/family/family', name: '成员设备', component: Family},
                {key: 'family-detail', path: '/family/detail', name: '家庭详情', hideInMenu: true, component: Detail},
                {key: 'family-device', path: '/family/device', name: '设备管理', hideInMenu: true, component: Device},
                {key: 'family-alarm', path: '/family/alarm', name: '报警管理', component: Alarm}]
        },
        {
            key: 'shop', path: '/shop', name: '商城管理', icon: <ShopOutlined/>,
            render: () => <Redirect to='/shop/package'/>, exact: true,
            routes: [{key: 'shop-package', path: '/shop/package', name: '套餐管理', component: Package},
                {key: 'shop-order', path: '/shop/order', name: '订单管理', component: Order}]
        }, {
            key: 'layout', path: '/layout', name: '版面管理', icon: <GoldOutlined/>,
            render: () => <Redirect to='/layout/banner'/>, exact: true,
            routes: [{key: 'layout-banner', path: '/layout/banner', name: '横幅管理', component: Banner},
                {key: 'layout-article', path: '/layout/article', name: '政策新闻', component: Article}]
        }
    ]
}

export const noAuthRoutes = {
    key: 'noAuth', path: '/', render: () => <Redirect to='/login'/>, exact: true,
    routes: [{key: 'login', path: '/login', component: Login}]
}

