import React, {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Spin} from "antd";
import {noAuthRoutes, routes} from "../../routes";

const routers = getRouters([routes])
const noAuthRouters = getRouters([noAuthRoutes])

export const Routers = () =>
    <Suspense fallback={<Spin style={{width: '100%'}}/>}>
        <Switch>
            {routers.map((props) => <Route {...props}/>)}
            <Route path='*' render={() => <Redirect to='/'/>}/>
        </Switch>
    </Suspense>

export const RoNoRouters = () =>
    <Suspense fallback={<Spin style={{width: '100%'}}/>}>
        <Switch>
            {noAuthRouters.map((props) => <Route {...props}/>)}
            <Route path='*' render={() => <Redirect to='/login'/>}/>
        </Switch>
    </Suspense>

function getRouters(routes = []) {
    return routes.reduce((prev = [], {routes, ...props} = {}) =>
        prev.concat([props], getRouters(routes)), [])
}
