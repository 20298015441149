import Cookies from "js-cookie"
import {useState} from "react"
import request from "umi-request";
import {message} from "antd";

export function useCookieState(cookieKey, options = {}) {
    const [state, setState] = useState(() => {
        return Cookies.get(cookieKey);
    })

    const updateState = (newValue, newOptions) => {
        setState(() => {
            if (newValue === undefined) {
                Cookies.remove(cookieKey);
            } else {
                Cookies.set(cookieKey, newValue, newOptions);
            }
            return newValue;
        })
    }
    return [state, updateState]
}

request.interceptors.request.use((url, options) => {
    const token = Cookies.get("TOKEN")
    if (token) {
        options.headers = {Authorization: token}
    }
    return {url, options}
})

request.interceptors.response.use(async (response, options) => {
    const data = await response.clone().json();
    if (+data.code === 401) {
        message.error("请重新登录")
        Cookies.remove("TOKEN")
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    } else if (+data.code === 50000) {
        message.error(data.message)
    }
    return response
})