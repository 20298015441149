import 'antd/dist/antd.css';
import '@ant-design/pro-table/dist/table.css';
import '@ant-design/pro-form/dist/form.css';
import 'moment/locale/zh-cn';
import zhCh from 'antd/es/locale/zh_CN'
import ConfigProvider from 'antd/es/config-provider'
import {BrowserRouter} from 'react-router-dom'
import {AuthorityProvider} from "./Kit/Auth/Provider"
import {Main} from "./Kit/Auth/Layout"

export const App = () =>
  <ConfigProvider locale={zhCh}>
    <BrowserRouter>
      <AuthorityProvider>
        <Main/>
      </AuthorityProvider>
    </BrowserRouter>
  </ConfigProvider>
