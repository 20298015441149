import "@ant-design/pro-layout/dist/layout.css"
import React, {useContext} from 'react'
import {Avatar, Button, Dropdown, Menu, message} from 'antd'
import {Link, useLocation} from 'react-router-dom'
import {AuthContext} from "./Provider";
import ProLayout, {PageContainer} from '@ant-design/pro-layout';
import {LogoutOutlined, UserOutlined, SettingOutlined} from "@ant-design/icons";
import logo from "../../Images/logo2.png"
import {RoNoRouters, Routers} from "./Routers";
import {routes} from "../../routes";
import {ModalForm, ProFormText} from "@ant-design/pro-form";
import request from "umi-request";

export const Main = () =>
    <AuthContext.Consumer>
        {({isAuthorized}) => isAuthorized ? <AuthLayout/> : <NoAuthLayout/>}
    </AuthContext.Consumer>

export const AuthLayout = () => {
    let {pathname} = useLocation();
    const {setToken} = useContext(AuthContext)
    return <ProLayout
        title="掌中孝" logo={logo} location={{pathname}} route={routes}
        menuItemRender={(item, dom) => <Link to={item.path}>{dom}</Link>}
        breadcrumbRender={(routes) => [{path: '/', breadcrumbName: '主页'}, ...(routes || [])]}
        rightContentRender={() => (<div>
            <Dropdown overlay={
                <Menu>
                    <Menu.Item icon={<SettingOutlined/>}>
                        <ModalForm title="修改密码" width="400px"
                                   trigger={<Button type="link" size="small">修改密码</Button>}
                                   autoFocusFirstInput modalProps={{
                            onCancel: () => console.log('run'),
                        }} onFinish={async (values) => {
                            await request.post('/admin/updatepwd', {data: values});
                            message.success('修改成功');
                            setToken()
                            return true;
                        }}>
                            <ProFormText.Password name="password" label="新密码" placeholder="请输入新密码"
                                                  rules={[{required: true, message: '请输入新密码!',}]}/>
                            <ProFormText.Password name="confirm" label="确认秘密" dependencies={['password']}
                                                  placeholder="请确认新密码"
                                                  rules={[
                                                      {required: true, message: '请确认新密码!'},
                                                      ({getFieldValue}) => ({
                                                          validator(_, value) {
                                                              if (!value || getFieldValue('password') === value) {
                                                                  return Promise.resolve();
                                                              }
                                                              return Promise.reject(new Error('两次密码不一致!'));
                                                          },
                                                      }),
                                                  ]}/>
                        </ModalForm>
                    </Menu.Item>
                    <Menu.Item icon={<LogoutOutlined/>}>
                        <Button type="link" size="small" onClick={() => setToken()}>退出</Button>
                    </Menu.Item>
                </Menu>}>
                <Avatar shape="circle" size="small" icon={<UserOutlined/>}/>
            </Dropdown>
        </div>)}>
        <PageContainer title={false}>
            <Routers/>
        </PageContainer>
    </ProLayout>
}

const NoAuthLayout = () => {
    return <RoNoRouters/>
}


